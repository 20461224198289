import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { generateClient } from "aws-amplify/api";
import { getParkingStallClosures } from "../../graphql/queries";

const client = generateClient();

const styles = (theme) => ({
  parkingNotices: {
    marginTop: theme.spacing(8),
    color: theme.palette.error.main,
    textAlign: "center",
    border: "2px solid  " + theme.palette.error.main,
    padding: theme.spacing(2),
  },
});

function ParkingNotices(props) {
  const { classes } = props;

  const [parkingStallClosureNotice, setParkingStallClosureNotice] =
    useState(null);

  useEffect(() => {
    fetchParkingStallClosureNotice();
  }, []);

  const fetchParkingStallClosureNotice = async () => {
    try {
      const response = await client.graphql({
        query: getParkingStallClosures,
        variables: { id: "84aee6ea-8031-474d-9986-c3e5f243650a" },
      });
      setParkingStallClosureNotice(response.data.getParkingStallClosures);
    } catch (error) {
      //setOpenAlert(true);
    }
  };

  return !parkingStallClosureNotice ? (
    <div className={classes.parkingNotices}>
      <Typography variant="body2">Loading...</Typography>
    </div>
  ) : (
    <div className={classes.parkingNotices}>
      <Typography variant="subtitle1">
        {parkingStallClosureNotice.title}
      </Typography>
      <Typography variant="h6" style={{ textDecoration: "underline" }}>
        {parkingStallClosureNotice.dateRange}
      </Typography>
      {parkingStallClosureNotice.closureDetails.map((detail, index) => (
        <Typography key={index} variant="body2">
          {detail}
        </Typography>
      ))}
    </div>
  );
}

export default withStyles(styles)(ParkingNotices);
