import { Flex, Text, Link, useTheme } from "@aws-amplify/ui-react";

export function Footer() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={tokens.space.medium}>
      <Text>
        &copy; Copyright 2024 - All Rights Reserved,{" "}
        <Link href="https://www.smh-hi.com/">Seal Masters of Hawaii</Link>
      </Text>
    </Flex>
  );
}
