export const listWailanaNotices = /* GraphQL */ `
  query ListWailanaNotices {
    listNotices(
      filter: { clientId: { eq: "4e276952-164d-4300-8ffc-ddbb8c92383f" } }
    ) {
      nextToken
      items {
        groupId
        pdfSource
        title
        id
        imgSource
      }
    }
  }
`;
